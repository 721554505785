<template src="./topmed.html"></template>

<script>
////////////////////// Chat texto //////////////////////////////////////////////
// https://github.com/MatheusrdSantos/vue-quick-chat
import Vue from "vue";
import { Chat } from "vue-quick-chat";
// import "vue-quick-chat/dist/vue-quick-chat.css";
// const signalR = require("signalr")

// import router from "@/router/router";
export default {
  components: {
    Chat,
  },
  data() {
    return {
      connection: null,
      tittle: null,
      url: null,
      chat: null,
      newMessage: "",
      sala: null,
      cliente: null,
      idAtendimento: null,
      einsteinUrl: "",
      log: "",
      idUsuario: null,
      checkParticipant: null,
      visible: true,
      participants: [
        {
          name: "Atendimento Topmed",
          id: 2,
          profilePicture: null,
        },
      ],
      myself: {
        name: this.nameClean(name),
        id: this.idUsuario,
        profilePicture: null,
      },
      messages: [],
      chatTitle: "Sala de atendimento",
      placeholder: "Escreva e envie sua mensagem",
      colors: {
        header: {
          bg: "#FF9F43",
          text: "#fff",
        },
        message: {
          myself: {
            bg: "#fff",
            text: "#000000",
          },
          others: {
            bg: "#FF9F43",
            text: "#fff",
          },
          messagesDisplay: {
            bg: "#f7f3f3",
          },
        },
        submitIcon: "#E56B1F",
        submitImageIcon: "#FF9F43",
      },
      borderStyle: {
        topLeft: "10px",
        topRight: "10px",
        bottomLeft: "10px",
        bottomRight: "10px",
      },
      hideCloseButton: false,
      submitIconSize: 25,
      closeButtonIconSize: "20",
      asyncMode: true,
      toLoad: [],
      scrollBottom: {
        messageSent: false,
        messageReceived: false,
      },
      displayHeader: true,
      profilePictureConfig: {
        others: false,
        myself: false,
        styles: {
          width: "30px",
          height: "30px",
          borderRadius: "50%",
        },
      },
      linkOptions: {
        myself: {
          className: "myLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
        others: {
          className: "othersLinkClass",
          events: {
            click: function () {
              alert("Link clicked!");
            },
            mouseover: function () {
              alert("Link hovered!");
            },
          },
          format: function (value, type) {
            if (type === "url" && value.length > 50) {
              value = value.slice(0, 50) + "…";
            }
            return value;
          },
        },
      },
    };
  },
  mounted: function () {
    var vm = this;
    vm.tittle = "Sala de Atendimento"
    vm.onbeforeunload();
    vm.init();
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  methods: {
    ios() {
      if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.einsteinMessageHandler) {
        const msg = this.einsteinUrl;
        window.webkit.messageHandlers.einsteinMessageHandler.postMessage(
          msg
        );
      }
    },
    init() {
      var vm = this;
      vm.name = Vue.prototype.$session.get("customerData").name;
      vm.chat = window.$.connection.chatHub;
      vm.getSala().then(() => {
        window.$(function ($) {
          $.connection.hub.logging = true;
          $.connection.hub.url = vm.url;
          $.connection.hub.qs = { "id": vm.idUsuario, "tipo": "beneficiario", "idAtendimento" : vm.idAtendimento }; 
          $.connection.hub.start().done(function () {
            vm.chat.server.joinRoom(vm.sala);
            console.log("Connected, transport = " + $.connection.hub.transport.name);
          });
        });
        
      });
      window.$.connection.chatHub.client.addNewMessageToPage = function (client) {
        if(client.name === vm.name || client.name === "" || client.name === null){
          vm.checkParticipant = 1;
        }else{
          vm.checkParticipant = 0;
          vm.cliente = client;
          vm.getMessage();
          vm.loadMoreMessages();
            
        }
      }
      vm.$vs.loading();
      vm.$vs.loading.close();
    },
    async getSala() {
      return new Promise((resolve, reject) => {
        const vm = this;
        vm.getUrl();
        vm.axios
          .get(vm.$store.state.filooServer + "topmed/chat")
          .then(function (response) {
            if (response.status === 200) {
              vm.sala = response.data.Sala;
              vm.idUsuario = response.data.IdUsuario;
              vm.idAtendimento = response.data.IdAtendimento;
            }
            resolve(response.data)
          })
          .catch(function () {
            vm.$bvModal.show("modal-topmed-link-error");
            reject(false);
          })
          .finally(() => {
            vm.$vs.loading.close();
          });
      })
    },
    onbeforeunload() {
      window.$.connection.hub.stop();
    },
    getUrl(){
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "topmed/url")
        .then(function (response) {
          if (response.status === 200) {
            vm.url = response.data + "signalr";
          }
        })
    },
    nameClean(str) {
      return str.substring(0, str.indexOf("-"));
    },
    onMessageSubmit: function (message) {
      const vm = this;
      vm.message = message.content;
      vm.messages.push(message);
      setTimeout(() => {
        message.uploaded = true
      }, 1000)
      vm.sendMessage();
    },
    loadMoreMessages () {
      const vm = this;
      setTimeout(() => {
      // resolve(vm.toLoad); 
        vm.messages.push(...vm.toLoad);
        vm.toLoad = [];
      }, 1000);
    },
    getMessage () {
      const vm = this;

      vm.checkParticipant === 1 ? "" : vm.participants[0].name = vm.cliente.name;
      vm.checkParticipant === 1 ? "" : vm.participants[0].name === "Bem Vindo!" ? "" : vm.tittle = vm.cliente.name;
      vm.toLoad =
        [{
          content: vm.cliente.message,
          myself: vm.checkParticipant === 1 ? true : false,
          participantId: vm.checkParticipant === 1 ? vm.idUsuario : 2,
          uploaded: true,
          viewed: false,
          type: "text",
        }];
    },
    sendMessage(){
      const vm = this;
      var client = { name: vm.name, message: vm.message }
      vm.chat.server.send(vm.sala, client);
    },   
  },
};
</script>

<style lang="scss" src="./topmed.scss"></style>
